<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${info.id===0?'新建':'编辑'}实验室`" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-form id="components-form-demo-validate-other" v-bind="formItemLayout" @submit="handleSubmit">

                    <a-form-item label="名称">
                        <a-input v-model="info.name"></a-input>
                    </a-form-item>

                    <a-form-item label="可用状态">
                        <a-switch v-model="info.status" :active-text="info.status ? '可用' : '不可用'"/>
                    </a-form-item>

                    <a-form-item label="是否在BI大屏中展示">
                        <a-radio-group name="radioGroup" v-model="info.is_show_BI">
                            <a-radio :value="1">是</a-radio>
                            <a-radio :value="2">否</a-radio>
                        </a-radio-group>
                    </a-form-item>


                    <a-form-item label="是否在首页展示">
                        <a-radio-group name="radioGroup" v-model="info.is_show_home">
                            <a-radio :value="1">是</a-radio>
                            <a-radio :value="2">否</a-radio>
                        </a-radio-group>
                    </a-form-item>


                    <div>
                        <a-form-item label="温度">
                            最小值
                            <a-input-number v-model="info.temperature_min"/>
                            最大值
                            <a-input-number v-model="info.temperature_max"/>

                            <a-switch v-model="info.isopen_temperature" :active-text="info.isopen_temperature ? '开启' : '关闭'" style="margin-left: 10px"/>
                        </a-form-item>
                        <a-form-item label="湿度">
                            最小值
                            <a-input-number v-model="info.humidity_min"/>
                            最大值
                            <a-input-number v-model="info.humidity_max"/>
                            <a-switch v-model="info.isopen_humidity" :active-text="info.isopen_humidity ? '开启' : '关闭'" style="margin-left: 10px"/>
                        </a-form-item>

                        <a-form-item label="负责人">
                            <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="options" placeholder="请选择" v-model="info.response_ids"/>
                        </a-form-item>

                        <a-form-item label="分组名称">
                            <a-input v-model="info.group_name"></a-input>
                        </a-form-item>
                        <a-form-item label="备注">
                            <a-textarea rows="3" v-model="info.remark"/>
                        </a-form-item>
                    </div>

                    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                        <a-button type="primary" @click="doClick">确定</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time'],

        data: () => ({
            options: [],
            info: {
                id: '',
                name: '',
                remark: '',
                type: '',
                status: '',
                isopen: '',
                temperature_max: '',
                temperature_min: '',
                humidity_max: '',
                humidity_min: '',
                isopen_temperature: '',
                isopen_humidity: '',
                response_id: '',
                response_ids: [],
                is_show_BI: '',
                is_show_home: '',
                group_name:''

            },
            do_type: false,

            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
            },

        }),
        // beforeCreate() {
        //     this.form = this.$form.createForm(this, { name: 'validate_other' });
        // },
        watch: {
            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.edit_info))
                if (this.info.response_ids === '') {
                    this.info.response_ids = []
                } else {
                    this.info.response_ids = this.info.response_ids.split(',').map((item) => {
                        return Number(item)
                    })
                }
                console.log('this.info.response_ids', this.info.response_ids)
                this.info.status = Number(this.info.status) === 1
                this.info.isopen_temperature = Number(this.info.isopen_temperature) === 1
                this.info.isopen_humidity = Number(this.info.isopen_humidity) === 1

                this.info.response_ids = this.info.response_ids.map((item) => {
                    return Number(item)
                })
            }
        },
        mounted() {
            this.info = JSON.parse(JSON.stringify(this.edit_info))
            if (this.info.response_ids === '') {
                this.info.response_ids = []
            } else {
                this.info.response_ids = this.info.response_ids.split(',').map((item) => {
                    return Number(item)
                })
            }
            console.log('this.info.response_ids', this.info.response_ids)
            this.info.status = Number(this.info.status) === 1
            this.info.isopen_temperature = Number(this.info.isopen_temperature) === 1
            this.info.isopen_humidity = Number(this.info.isopen_humidity) === 1


            this.Dandaalllist()
            //
            this.info.response_ids = this.info.response_ids.map((item) => {
                return Number(item)
            })

        },
        methods: {
            //获取部门人员分级列表
            Dandaalllist() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.options = response.data.list;
                            console.log(this.options)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            doClick() {
                console.log("111");
                let data = {
                    name: this.info.name,
                    remark: this.info.remark,
                    type: this.info.type,
                    status: this.info.status ? 1 : 2,
                    id: this.info.id,
                    pid: 0,
                    isopen: this.info.isopen ? 1 : 2,
                    temperature_max: this.info.temperature_max,
                    temperature_min: this.info.temperature_min,
                    humidity_max: this.info.humidity_max,
                    humidity_min: this.info.humidity_min,
                    isopen_temperature: this.info.isopen_temperature ? 1 : 2,
                    isopen_humidity: this.info.isopen_humidity ? 1 : 2,
                    response_id: this.info.response_ids[this.info.response_ids.length - 1],
                    is_show_home: this.info.is_show_home,
                    is_show_BI: this.info.is_show_BI,
                    group_name: this.info.group_name,
                }
                console.log(JSON.stringify(data))
                console.log(data)
                //return
                this.$sa0.post({
                    url: this.info.id !== 0 ? this.$api('Edit_laboratory') : this.$api('Create_laboratory'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            // normFile(e) {
            //     console.log('Upload event:', e);
            //     if (Array.isArray(e)) {
            //         return e;
            //     }
            //     return e && e.fileList;
            // },
        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }
</style>
