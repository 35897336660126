<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="第一实验室监控记录" width="720" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>

        <table>
          <tr>
            <th>温度</th>
            <th>湿度</th>
            <th>监控执行时间</th>
            <th>操作</th>
          </tr>

          <tr>
            <td class="w25"><a-input v-model="create_info.wendu" v-if="edit_info.isopen_temperature==1"/></td>
            <td class="w25"><a-input v-model="create_info.shidu" v-if="edit_info.isopen_humidity==1"/></td>
            <td>
              <a-date-picker v-model="create_info.exe_time" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" @change="(e,dateString)=>{onChangetime(e,dateString,'exe_time')}"/>
            </td>
            <td>
              <a-space size="small">
                <a-button type="primary" @click="doClick()">添加</a-button>
              </a-space>
            </td>
          </tr>

          <template v-for="(item,key) in laboratorymonitor_list">

            <tr>
              <td  v-if="edit_info.isopen_temperature==1"><a-input v-model="item.temperature" />
              </td>
              <td v-else>{{item.temperature}}</td>
              <td v-if="edit_info.isopen_humidity==1"><a-input v-model="item.humidity"  />
              </td>
              <td v-else>{{item.humidity}}</td>
              <td>
                <a-date-picker v-model="item.exe_time" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" @change="(e,dateString)=>{onChangetime(e,dateString,'exe_time')}"/>
              </td>
              <td>
                <a-space size="small">
                  <a-button type="primary" @click="doClick_edit(item)">编辑</a-button>
                  <a-button type="danger" @click="doClick_del(item)">删除</a-button>
                </a-space>
              </td>
            </tr>

            <tr v-if="item.loglist !==undefined && item.loglist != null  && item.loglist.length > 0">
              <td colspan="4" class="pad0">
                <table class="neirong" >
                  <tr>
                    <th>温度</th>
                    <th>湿度</th>
                    <th>监控执行时间</th>
                  </tr>
                  <tr v-for="(item_loglist,item_loglist_key) in item.loglist" :key="item_loglist_key">
                    <td class="noborder-b w25">{{ item_loglist.temperature }}</td>
                    <td class="noborder-b w25">{{ item_loglist.humidity }}</td>
                    <td class="noborder-b" colspan="2">{{ item_loglist.exe_time }}</td>
                  </tr>
                </table>
              </td>
            </tr>

          </template>

        </table>

        <a-pagination :current="1" :pageSize="pagesize" @change="onPageChange" :total="count" v-if="count>pagesize"/>

      </div>
    </a-drawer>

  </div>
</template>
<script>
/***
 Name: example3
 Code: sa0ChunLuyu
 Time: 2021/10/16 09:47
 Remark: 编辑抽屉示例的抽屉组件
 */
import moment from 'moment';

export default {
  // 接收 显示变量 和 关闭函数
  props: ['visible', 'close', 'edit_info', 'edit_time'],

  data: () => ({

    create_info: {
      wendu: '',
      shidu: '',
      exe_time: ''
    },
    info: false,
    formItemLayout: {
      labelCol: {span: 6},
      wrapperCol: {span: 14},
    },
    count: 0,
    pagesize: 0,
    laboratorymonitor_list: []
  }),

  mounted() {
    this.info = JSON.parse(JSON.stringify(this.edit_info))
    this.Get_laboratorymonitor(0)
  },
  watch: {
    edit_time() {
      this.info = JSON.parse(JSON.stringify(this.edit_info))
      this.Get_laboratorymonitor(0)
    }
  },
  methods: {
    //编辑保存
    doClick() {
      console.log("111");
      let data = {
        laboratory_id: this.info.id,
        temperature: this.create_info.wendu,
        humidity: this.create_info.shidu,
        exe_time: this.create_info.exe_time,
      }
      console.log(JSON.stringify(data))
      console.log(data)
      //return
      this.$sa0.post({
        // url: this.info.id !== 0 ? this.$api('Edit_laboratorymonitor') : this.$api('Create_laboratorymonitor'),
        url: this.$api('Create_laboratorymonitor'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            this.close()
            this.create_info={
              wendu: '',
              shidu: '',
              exe_time: ''
            }

          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },

    doClick_edit(item) {
      console.log("111");
      let data = {
        laboratory_id: this.info.id,
        temperature: item.temperature,
        humidity: item.humidity,
        exe_time:item.exe_time,
        id:item.id
      }
      console.log(JSON.stringify(data))
      console.log(data)
      //return
      this.$sa0.post({
        // url: this.info.id !== 0 ? this.$api('Edit_laboratorymonitor') : this.$api('Create_laboratorymonitor'),
        url: this.$api('Edit_laboratorymonitor'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            this.close()
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },

    doClick_del(item) {
      console.log("del-------------");
      let data = {
        id:item.id
      }
      console.log(JSON.stringify(data))
      console.log(data)
      //return
      this.$sa0.post({
        // url: this.info.id !== 0 ? this.$api('Edit_laboratorymonitor') : this.$api('Create_laboratorymonitor'),
        url: this.$api('Del_laboratorymonitor'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            this.close()
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },




    // 分页
    onPageChange(page) {
      this.Get_laboratorymonitor(page)
    },
    // 获取实验室监控记录
    Get_laboratorymonitor(page) {
      console.log(this.$api('Get_laboratorymonitor'))
      this.$sa0.post({
        url: this.$api('Get_laboratorymonitor'),
        data: {
          laboratory_id: this.info.id,
          page: page
        },

      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.laboratorymonitor_list = response.data.return.list;
            this.count = response.data.return.count;//分页
            this.pagesize = response.data.return.pagesize;//分页
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })

    },
    // 时间选择器
    // moment,
    // onChange(time, timeString) {
    //   console.log(time, timeString);
    //   this.info.exe_time = timeString;
    // },
    // 定义 组件 自身的关闭函数
    onClose() {
      this.close();
    },

    onChangetime(value, dateString, e) {
      this.info.exe_time = dateString;
    },
    onOk(value) {
      console.log('onOk: ', value);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
    normFile(e) {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
  }
}
</script>

<style scoped>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}

table {
  width: 100%;
  border-top: solid 1px #d9d9d9;
  border-left: solid 1px #d9d9d9;
}
table td, table th {
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
  padding: 10px 15px;
  line-height: 1;
  vertical-align: top;
}
table th {
  background: #f5f5f5
}

table td p {
  margin: 0.5rem 0;
  line-height: 1.6
}

table td.pad0 {padding: 0}

table.neirong {
  margin: 15px auto;
  width: 92%;
  border: none;
}
table.neirong td,table.neirong th{
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1px #efefef;
  padding-left: 25px;
  line-height: 1.6;
}

</style>
