<template>
    <div>
        <a-tabs type="card" style="margin-left: 20px" @change="onChange">
            <a-tab-pane tab="全部" key=""></a-tab-pane>
            <a-tab-pane :tab="item.group_name" v-for="(item,key) in group_list" :key="item.group_name"></a-tab-pane>
        </a-tabs>
        <!--实验室内容主体-->
        <div class="lab-main">
            <!--新建实验室-->
            <div class="card card-add" @click="showDrawer({
            id:0,
            name:'',
            remark:'',
            status:'',
            isopen:'1',
            temperature_max:'',
            temperature_min:'',
            humidity_max:'',
            humidity_min:'',
            sopen_temperature:'1',
            isopen_humidity:'1',
            response_ids:''
            })"><img src="http://api.tengbocn.com/upload/assets/add1.png">
                <p>点击添加实验室</p>
            </div>

            <!--实验室列表-->
            <div class="card" v-for="(item,key) in lab_list">
                <template v-if="item.status === 1">
                    <div class="lab-tag">
                        <div class="item-a">开放中</div>
                    </div>
                </template>
                <template v-if="item.status === 0">
                    <div class="lab-tag">
                        <div class="item-a item-b">已关闭</div>
                    </div>
                </template>


                <div class="card-top" style="height: 100px">

                    <!--温度-->
                    <div style="width: 50%">
                        <template v-if="item.isopen_temperature == 1">
                            <div v-if=" item.laboratory_temperature == 1">
                                <div class="card-top-item pink" v-if="item.temperature == '无数值'">
                                    <img src="http://api.tengbocn.com/upload/assets/icon-wendu-pink.png">
                                    <span style="font-size: 16px">{{ item.temperature }}</span>
                                </div>
                                <div class="card-top-item pink" v-else>
                                    <img src="http://api.tengbocn.com/upload/assets/icon-wendu-pink.png">
                                    <span>{{ item.temperature }}</span><b>℃</b>
                                </div>
                            </div>

                            <div v-else>
                                <div class="card-top-item">
                                    <img src="http://api.tengbocn.com/upload/assets/icon-wendu.png">
                                    <span>{{ item.temperature }}</span><b>℃</b>
                                </div>
                            </div>

                        </template>
                    </div>
                    <!--湿度-->
                    <div>
                        <template v-if="item.isopen_humidity == 1">
                            <div v-if="item.laboratory_humidity == 1">
                                <div class="card-top-item pink" v-if="item.humidity == '无数值'">
                                    <img src="http://api.tengbocn.com/upload/assets/icon-shidu-pink.png">
                                    <span style="font-size: 16px">{{ item.humidity }}</span>
                                </div>
                                <div class="card-top-item pink" v-else>
                                    <img src="http://api.tengbocn.com/upload/assets/icon-shidu-pink.png">
                                    <span>{{ item.humidity }}</span><b>%</b>
                                </div>
                            </div>
                            <div v-else>
                                <div class="card-top-item">
                                    <img src="http://api.tengbocn.com/upload/assets/icon-shidu.png">
                                    <span>{{ item.humidity }}</span><b>%</b>
                                </div>
                            </div>
                        </template>
                    </div>

                </div>


                <!--        <div class="card-top" style="height: 100px">-->

                <!--          &lt;!&ndash;温度&ndash;&gt;-->
                <!--          <div style="width: 50%">-->
                <!--            <template v-if="item.isopen_temperature == 1">-->
                <!--              <div v-if=" item.laboratory_temperature == 1">-->
                <!--                <div class="card-top-item pink" >-->
                <!--                  <img src="http://api.lims.tengbocn.com/upload/assets/icon-wendu-pink.png">-->

                <!--                  <span style="font-size: 16px" v-if="item.temperature == '无数值'">{{ item.temperature }}</span>-->

                <!--                  <span style="font-size: 16px" v-else>{{ item.temperature }}<b>℃</b></span>-->

                <!--                </div>-->
                <!--&lt;!&ndash;                <div class="card-top-item pink" v-else>&ndash;&gt;-->
                <!--&lt;!&ndash;                  <img src="http://api.lims.tengbocn.com/upload/assets/icon-wendu-pink.png">&ndash;&gt;-->
                <!--&lt;!&ndash;                  <span>{{ item.temperature }}</span><b>℃</b>&ndash;&gt;-->
                <!--&lt;!&ndash;                </div>&ndash;&gt;-->
                <!--              </div>-->

                <!--              <div v-else>-->
                <!--                <div class="card-top-item" >-->
                <!--                  <img src="http://api.lims.tengbocn.com/upload/assets/icon-wendu.png">-->
                <!--                  <span>{{ item.temperature }}</span><b>℃</b>-->
                <!--                </div>-->
                <!--              </div>-->

                <!--            </template>-->
                <!--          </div>-->
                <!--          &lt;!&ndash;湿度&ndash;&gt;-->
                <!--          <div>-->
                <!--            <template v-if="item.isopen_humidity == 1">-->
                <!--              <div v-if="item.laboratory_humidity == 1">-->
                <!--                <div class="card-top-item pink" >-->
                <!--                  <img src="http://api.lims.tengbocn.com/upload/assets/icon-shidu-pink.png">-->
                <!--                  <span style="font-size: 16px">{{ item.humidity }}</span>-->
                <!--                </div>-->
                <!--&lt;!&ndash;                <div class="card-top-item pink" v-else>&ndash;&gt;-->
                <!--&lt;!&ndash;                  <img src="http://api.lims.tengbocn.com/upload/assets/icon-shidu-pink.png">&ndash;&gt;-->
                <!--&lt;!&ndash;                  <span>{{ item.humidity }}</span><b>%</b>&ndash;&gt;-->
                <!--&lt;!&ndash;                </div>&ndash;&gt;-->
                <!--              </div>-->
                <!--              <div v-else>-->
                <!--                <div class="card-top-item" >-->
                <!--                  <img src="http://api.lims.tengbocn.com/upload/assets/icon-shidu.png">-->
                <!--                  <span>{{ item.humidity }}</span><b>%</b>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--            </template>-->
                <!--          </div>-->

                <!--        </div>-->


                <div class="card-down">
                    <p class="text-xl">{{ item.name }}</p>
                    <p class="text-xs">{{ item.remark }}</p>
                    <p class="text-xs">负责人：{{ item.response_name }}</p>
                    <p class="text-xs">分组名称：{{ item.group_name }}</p>
                </div>
                <div class="card-btn">
                    <a-button type="primary" @click="showDrawer(item)">编辑实验室</a-button>
                    <a-button type="primary" @click="showDrawer1(item)">监控记录</a-button>
                    <template>
                        <a-button type="primary" v-if="item.status===1" @click="closelab(item,0)">关闭实验室</a-button>
                    </template>
                    <template>
                        <a-button type="primary" v-if="item.status===0" @click="closelab(item,1)">打开实验室</a-button>
                    </template>
                </div>
            </div>

        </div>

        <!--新建、编辑实验室-->
        <div v-if="edit_info">
            <editLab :edit_time="edit_time" :edit_info="edit_info" :close="onCloseDrawer" :visible="drawer_visible"></editLab>
        </div>

        <!--查看监控记录-->
        <div v-if="edit_info">
            <listLab :edit_time="edit_time" :edit_info="edit_info" :close="onCloseDrawer1" :visible="drawer_visible1"></listLab>
        </div>


    </div>
</template>
<script>
    import editLab from '../../../components/drawer/lab_open/lab-open.vue';
    import listLab from '../../../components/drawer/lab_open1/lab_open1.vue';

    export default {
        components: {
            editLab,
            listLab,
        },
        data() {
            return {
                edit_time: 0,
                edit_info: false,
                lab_list: [],
                group_list: [],
                // 定义控制抽屉显示的变量
                drawer_visible: false,
                drawer_visible1: false,
                group_name: '',
            }
        },

        watch: {
            $route: {
                handler() {
                    this.group_name = this.$route.query.group_name || '';
                    this.Get_laboratory()
                },
                deep: true
            }
        },

        mounted() {
            this.group_name = this.$route.query.group_name || '';
            this.Get_laboratory()// w 获取实验室分页列表
        },
        methods: {
            //关闭实验室
            closelab(item, status) {
                this.$sa0.post({
                    url: this.$api('Set_laboratory'),
                    data: {
                        id: item.id,
                        status: status,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_laboratory()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //获取实验室列表
            Get_laboratory() {
                this.$sa0.post({
                    url: this.$api('Get_laboratory'),
                    data: {
                        group_name: this.group_name ? this.group_name : '',
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.lab_list = response.data.list;
                            this.group_list = response.data.group_list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            onChange(e) {
                this.$router.push({
                    query: {
                        group_name: e,
                    }
                })
            },

            //编辑、新建实验室
            // 定义 打开抽屉时的 函数
            showDrawer(item) {
                // this.$set(this,'edit_info',item)
                console.log(item)
                this.edit_info = item
                this.edit_time = new Date() / 1
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.Get_laboratory();
            },

            //查看监控记录
            // 定义 打开抽屉时的 函数
            showDrawer1(item) {
                this.edit_info = item
                this.edit_time = new Date() / 1
                this.drawer_visible1 = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer1() {
                this.drawer_visible1 = false;
                this.Get_laboratory();
            },

        }
    }
</script>


<style scoped>
    .lab-main{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        }

    .card{
        position: relative;
        margin: 15px 0 0 15px;
        min-width: 420px;
        padding: 30px;
        background: #fff;
        border: solid 1px #efefef;
        box-sizing: border-box;
        }

    .lab-tag{
        position: absolute;
        top: 15px;
        right: 15px;
        width: 100%;
        }

    .lab-tag .item-a{
        float: right;
        width: 62px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: url("../../../../public/assets/icon-blue.png") no-repeat;
        color: #fff;
        font-size: 12px;
        }

    .lab-tag .item-b{
        background: url("../../../../public/assets/icon-gray.png") no-repeat;
        }

    .lab-tag .item-c{
        margin-left: 5px;
        background: url("../../../../public/assets/icon-pink.png") no-repeat;
        }

    .card-add{
        padding-top: 100px;
        text-align: center;
        background: #fff;
        border: solid 1px #efefef;
        color: #1890ff;
        }

    .card-add p{
        padding-top: 10px
        }

    .card-add:hover, .card:hover{
        background: #f5f5f5;
        cursor: pointer;
        }

    .card img{
        display: inline
        }

    .card-top{
        display: flex;
        justify-content: left;
        padding: 15px;
        border-bottom: solid 1px #e5e5e5;
        }

    .card-top-item{
        width: 100%
        }

    .card-top span{

        margin: 0 5px 0 15px;
        vertical-align: middle;
        font-size: 32px
        }

    .card-top b{
        font-weight: normal;
        vertical-align: -10px
        }

    .card-down{
        padding-top: 1rem
        }

    .card-down p{
        margin-bottom: 0.5rem
        }

    .card-btn{
        padding-top: 0.5rem
        }

    .card-btn button{
        margin-right: 15px;
        }

    .pink{
        color: #f15b5b
        }

</style>
